jQuery(document).foundation();

//Toggles class for animation for the hamburger menu on the mobile nav.
jQuery(document).ready(function(){
    jQuery(".hamburger").click(function(){
      jQuery(this).toggleClass("hamburger-transform");
    });
  });

//Toggles class to .title-bar when scrolling takes place.
jQuery(document).scroll(function(){
  jQuery('.nav-wrapper').toggleClass('nav-scrolled', 
  jQuery(this).scrollTop() > 25);
});